import React, { useEffect, useState } from "react";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../Methods/Save";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function Products(props) {
  // old
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [defaultProduct, setDefaultProduct] = useState([]);
  const [defaultRelatedProduct, setDefaultRelatedProduct] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [vertical, setvertical] = useState("top");
  const [horizontal, sethori] = useState("right");
  const [message, setMessage] = useState("");
  const [alerttype, setAlertType] = useState("");

  var default_products = [];
  var default_products_id = [];
  var default_products_catId = [];
  var default_rental_products = {};

  var {
    ownRouter,
    setOwnRouter,
    setAddOns,
    setMonthlyTotal,
    monthlyTotal,
    addons,
    data,
    products,
    setProducts,
    setUpfrontPaymnet,
    landLineNumber,
    upfrontPayment,
    rentalProducts,
    setRentalProducts,
    setIds,
    ids,
    related_ids,
    setRelated_Ids,
    rental_ids,
    setRental_Ids,
    addonsParentCategory,
    setAddonsParentCategory,
    addedCategory,
    setAddedCategory,
    addedRentCategory,
    setAddedRentCategory,
    setAddedRentalProducts,
    addedRentalProducts,
    setAddedProducts,
    addedProducts,
    setDeliveryPrice,
    agent,
    agentOrder,
  } = props;
  useEffect(() => {
    if (localStorage.getItem("addon_ids") !== null) {
      setIds(JSON.parse(localStorage.getItem("addon_ids")));
    }
    if (localStorage.getItem("addons") !== null) {
      setAddOns(JSON.parse(localStorage.getItem("addons")));
    }
    if (localStorage.getItem("related_ids") !== null) {
      setRelated_Ids(JSON.parse(localStorage.getItem("related_ids")));
    }
    if (localStorage.getItem("addonproducts") !== null) {
      setProducts(JSON.parse(localStorage.getItem("addonproducts")));
    }
    if (localStorage.getItem("rental_ids") !== null) {
      setRental_Ids(JSON.parse(localStorage.getItem("rental_ids")));
    }
    if (localStorage.getItem("rentalProducts") !== null) {
      setRentalProducts(JSON.parse(localStorage.getItem("rentalProducts")));
    }
    if (localStorage.getItem("addonsParentCategory") !== null) {
      setAddonsParentCategory(
        JSON.parse(localStorage.getItem("addonsParentCategory"))
      );
    }
  }, []);
  useEffect(() => {
    var page = localStorage.getItem("page") || "broadband";
    if (page == "ff") page = "broadband";
    var id = JSON.parse(localStorage.getItem("Product"))?.id;
    if (data === "calling") {
      var url = APIURL() + `addons/${id}/${page}`;
    } else if (data === "products") {
      var url = APIURL() + `related_products/${id}/${page}`;
    }
    axiosGet(url).then((response) => {
      setCallingBundles(response.data[0].response.data);

      if (data === "products" && !agentOrder) {
        if (response.data[0].response.data.length === 0) navigate("/goLive");
      }
    });
  }, []);
  const [callingBundles, setCallingBundles] = useState();
  // useEffect(() => {
  //   if (callingBundles && callingBundles.length > 0) {
  //     var page = localStorage.getItem("page") || "broadband";
  //     if (page == "broadband" || page == "ff") {
  //       var page = localStorage.getItem("page") || "broadband";
  //       var id = JSON.parse(localStorage.getItem("Product")).id;
  //       var url = APIURL() + `default-Product`;
  //       var data = {
  //         product_id: id,
  //         product_type: "broadband",
  //       };
  //       axiosPost(url, data).then((response) => {
  //         // setAddOns(response.data[0].response.addon_data.Subcategory);
  //         if (response.data[0].response.addon_data.Subcategory) {
  //           setDefaultProduct((oldArray) => [
  //             ...oldArray,
  //             ...response.data[0].response.addon_data.Subcategory,
  //           ]);
  //         }
  //         if (response.data[0].response.related_data.Subcategory)
  //           setDefaultRelatedProduct((oldArray) => [
  //             ...oldArray,
  //             ...response.data[0].response.related_data.Subcategory,
  //           ]);
  //       });
  //     }
  //   }
  // }, [callingBundles]);

  if (localStorage.getItem("monthlyTotal") !== null) {
    var startingmonthlyCost = localStorage.getItem("startingmonthlyCost");
  }
  if (localStorage.getItem("initial_installation_price") !== null) {
    var initial_installation_price = localStorage.getItem(
      "initial_installation_price"
    );
  }
  if (localStorage.getItem("initalmonthlyTotal") !== null) {
    var initalmonthlyTotal = localStorage.getItem("initalmonthlyTotal");
  }
  if (localStorage.getItem("upfrontPayment") !== null) {
    var initialUpfrontPayment = localStorage.getItem("upfrontPayment");
  }

  const removeProduct = (subcat, res) => {
    if (subcat.mandatory == 1) {
      setMessage("Product is mandatory hence cannot be removed");
      setAlertType("error");
      setOpen(true);
      return;
    }
    setMessage("Product removed");
    setAlertType("error");
    setOpen(true);
    setProducts((current) =>
      current.filter((product) => product.id !== subcat.id)
    );
    var ids_list = [...related_ids];
    var new_ids_list = ids_list.filter((id) => id !== subcat.id);
    setRelated_Ids(new_ids_list);
    setRentalProducts((current) =>
      current.filter((product) => product.id !== subcat.id)
    );
    setRental_Ids((id) => id.filter((product) => product !== subcat.id));

    setAddedCategory((addedCategory) =>
      addedCategory.filter((category) => category !== res.category_id)
    );
    setAddedRentCategory((addedCategory) =>
      addedCategory.filter((category) => category !== res.category_id)
    );
    // for (const property in addedProducts) {
    //   console.warn(property);
    // }
    var addpros = {};
    for (const [key, value] of Object.entries(addedProducts)) {
      if (value == subcat.id) {
        continue;
      } else {
        addpros[key] = value;
      }
    }
    setAddedProducts(addpros);
    var addrelpros = {};
    for (const [key, value] of Object.entries(addedRentalProducts)) {
      if (value == subcat.id) {
        continue;
      } else {
        addrelpros[key] = value;
      }
    }
    setAddedRentalProducts(addrelpros);
    // addedProducts.forEach(element => {
    //   console.warn(element);
    // });
    // setAddedProducts((addedProducts) =>
    //   addedProducts.filter((category) => category !== res.Category)
    // );
  };
  const removeAllProducts = () => {
    // Logic to clear all products and related state
    setOwnRouter(1);
    setProducts([]);
    setRelated_Ids([]);
    setRentalProducts([]);
    setRental_Ids([]);
    setAddedCategory([]);
    setAddedRentCategory([]);
    setAddedProducts({});
    setAddedRentalProducts({});

    // Display a message or alert indicating all products have been removed
    setMessage("All products removed");
    setAlertType("info");
    setOpen(true);
  };

  const addToExistingProduct = (subcat, res) => {
    setAddedProducts({ ...addedProducts, [res.category_id]: subcat.id });
    setOpen(true);
    setMessage("Added Successfully");
    setAlertType("success");
    setAddedCategory((addedCategory) => [...addedCategory, res.category_id]);
    setProducts((products) => [...products, subcat]);
    setRelated_Ids((oldArray) => [...related_ids, subcat.id]);
    // setUpfrontPaymnet(Number(upfrontPayment) + subcat.direct_price);
  };
  console.log("rentalProducts", rentalProducts);
  console.log("products", products);
  const replaceExistingProductDirect = (subcat, res) => {
    var id = "";
    var rent_id = "";
    var ids_list = [];
    var rental_ids_list = [];

    if (res.category_id in addedProducts) {
      id = addedProducts[res.category_id];
      setAddedProducts({ ...addedProducts, [res.category_id]: subcat.id });
      var new_list = products.filter((product) => product.id !== id);
      setProducts((oldArray) => [...new_list, subcat]);

      // var old_list = [...products];
      [...new_list, subcat].forEach((element) => {
        ids_list.push(element.id);
      });
      setRelated_Ids(ids_list);
    }
    if (res.category_id in addedRentalProducts) {
      rent_id = addedRentalProducts[res.category_id];
      var new_list_rent = rentalProducts.filter(
        (product) => product.id !== rent_id
      );
      // setAddedRentalProducts({
      //   ...addedRentalProducts,
      //   [res.category_id]: subcat.id,
      // });
      [...new_list_rent].forEach((element) => {
        rental_ids_list.push(element.id);
      });
      setRentalProducts((oldArray) => [...new_list_rent]);
      setRental_Ids(rental_ids_list);

      setAddedProducts({ ...addedProducts, [res.category_id]: subcat.id });
      var new_list = products.filter((product) => product.id !== id);
      setProducts((oldArray) => [...new_list, subcat]);

      // var old_list = [...products];
      [...new_list, subcat].forEach((element) => {
        ids_list.push(element.id);
      });
      setRelated_Ids(ids_list);
    }
    setOpen(true);
    setMessage("Replaced Successfully");
    setAlertType("success");
  };
  const replaceExistingProduct = (subcat, res) => {
    setOwnRouter(2);
    var id = "";
    var rent_id = "";
    var ids_list = [];
    var rental_ids_list = [];

    if (res.category_id in addedProducts) {
      id = addedProducts[res.category_id];
      rent_id = addedRentalProducts[res.category_id];
      // setAddedProducts({ ...addedProducts, [res.category_id]: subcat.id });
      var new_list = products.filter((product) => product.id !== id);
      setProducts((oldArray) => [...new_list]);

      // var old_list = [...products];
      [...new_list].forEach((element) => {
        ids_list.push(element.id);
      });
      setRelated_Ids(ids_list);
      var new_list_rent = rentalProducts.filter(
        (product) => product.id !== rent_id
      );
      setAddedRentalProducts({
        ...addedRentalProducts,
        [res.category_id]: subcat.id,
      });
      [...new_list_rent, subcat].forEach((element) => {
        rental_ids_list.push(element.id);
      });
      setRentalProducts((oldArray) => [...new_list_rent, subcat]);
      setRental_Ids(rental_ids_list);
    }
    if (res.category_id in addedRentalProducts) {
      rent_id = addedRentalProducts[res.category_id];
      var new_list_rent = rentalProducts.filter(
        (product) => product.id !== rent_id
      );
      setAddedRentalProducts({
        ...addedRentalProducts,
        [res.category_id]: subcat.id,
      });
      [...new_list_rent, subcat].forEach((element) => {
        rental_ids_list.push(element.id);
      });
      setRentalProducts((oldArray) => [...new_list_rent, subcat]);
      setRental_Ids(rental_ids_list);
    }
    setOpen(true);
    setMessage("Replaced Successfully");
    setAlertType("success");
  };
  const addNewProduct = (subcat, res) => {
    setAddedCategory((addedCategory) => [...addedCategory, res.category_id]);
    setAddedProducts({ ...addedProducts, [res.category_id]: subcat.id });
    setOpen(true);
    setMessage("Added Successfully");
    setAlertType("success");
    setProducts((oldArray) => [...oldArray, subcat]);
    setRelated_Ids((oldArray) => [...oldArray, subcat.id]);
    // setUpfrontPaymnet(Number(initialUpfrontPayment) + Number(subcat.direct_price));
  };
  const addNewRentalProduct = (subcat, res) => {
    setOwnRouter(2);
    setOpen(true);
    setMessage("Added Successfully");
    setAlertType("success");
    setAddedRentCategory((addedCategory) => [
      ...addedCategory,
      res.category_id,
    ]);
    setRentalProducts((oldArray) => [...oldArray, subcat]);
    setAddedRentalProducts({
      ...addedRentalProducts,
      [res.category_id]: subcat.id,
    });
    setRental_Ids((oldArray) => [...oldArray, subcat.id]);
    // setUpfrontPaymnet(Number(upfrontPayment) + subcat.direct_price);
  };
  useEffect(() => {
    var price = 0;
    var del_price = 0;
    if (products) {
      if (products.length > 0) {
        products.forEach((element) => {
          price += element.direct_price;
          del_price += element.delivery_price;
        });
      }
    }
    if (rentalProducts) {
      if (rentalProducts.length > 0) {
        rentalProducts.forEach((element) => {
          del_price += element.delivery_price;
        });
      }
    }

    if (products && data == "products") {
      localStorage.setItem("addonproducts", JSON.stringify(products));
      localStorage.setItem("related_ids", JSON.stringify(related_ids));
      localStorage.setItem("addedCategory", JSON.stringify(addedCategory));
      localStorage.setItem("addedProducts", JSON.stringify(addedProducts));
    }
    if (
      landLineNumber &&
      landLineNumber != "" &&
      (landLineNumber.startsWith("01") || landLineNumber.startsWith("02"))
    )
      initial_installation_price = 0;
    setUpfrontPaymnet(
      Number(initial_installation_price) + Number(price) + Number(del_price)
    );
    setDeliveryPrice(del_price);
  }, [products, initial_installation_price, rentalProducts, landLineNumber]);
  // useEffect(() => {
  //   var price = 0;

  //   setMonthlyTotal(Number(startingmonthlyCost) + Number(price));
  // useEffect(() => {
  //   if (localStorage.getItem("edit_mode") == null && data == "products") {
  //     localStorage.setItem("addonproducts", JSON.stringify(products));
  //     localStorage.setItem("related_ids", JSON.stringify(related_ids));
  //   }
  // }, [products,localStorage.getItem("edit_mode")]);
  // }, [rentalProducts]);
  const removeAddons_multiple = (subcat) => {
    setOpen(true);
    setMessage("Add-On removed");
    setAlertType("error");
    var ids_list = [...ids];
    var new_ids_list = ids_list.filter((id) => id !== subcat.id);
    var old_list = [...addons];
    var new_list = old_list.filter((addon) => addon.id !== subcat.id);
    setAddOns(new_list);
    setIds(new_ids_list);
    // setMonthlyTotal(monthlyTotal - subcat.price);
  };
  const replaceAddons = (subcat, parent_id) => {
    setOwnRouter(2);
    setOpen(true);
    setMessage("Add-On Replaced");
    setAlertType("success");
    var ids_list = [];

    var old_list = [...addons];
    var new_list = old_list.filter((addon) => addon.category_id !== parent_id);
    new_list.push(subcat);
    new_list.forEach((element) => {
      ids_list.push(element.id);
    });
    setAddOns(new_list);
    setIds(ids_list);
  };
  useEffect(() => {
    var price = 0;
    var del_price = 0;
    if (addons && addons.length > 0) {
      addons.forEach((element) => {
        price += element.price;
      });
    }
    if (rentalProducts) {
      if (rentalProducts.length > 0) {
        rentalProducts.forEach((element) => {
          price += element.price;
        });
      }
    }
    if (rentalProducts && data == "products") {
      localStorage.setItem("rentalProducts", JSON.stringify(rentalProducts));
      localStorage.setItem("rental_ids", JSON.stringify(rental_ids));
      localStorage.setItem(
        "addedRentalProducts",
        JSON.stringify(addedRentalProducts)
      );
      localStorage.setItem(
        "addedRentCategory",
        JSON.stringify(addedRentCategory)
      );
    }
    setMonthlyTotal(Number(startingmonthlyCost) + Number(price));
  }, [addons, rentalProducts]);
  useEffect(() => {
    if (
      data === "calling" &&
      callingBundles &&
      callingBundles.length > 0 &&
      defaultProduct.length > 0 &&
      products.length == 0 &&
      rentalProducts.length == 0
    ) {
      defaultProduct.forEach((dp) => {
        callingBundles.forEach((cb) => {
          if (dp.category_id == cb.category_id) {
            cb.Subcategory.forEach((element) => {
              if (dp.id == element.id) {
                default_products.push(element);
                default_products_id.push(element.id);
                default_products_catId.push(dp.category_id);
              }
            });
          }
        });
      });
      setIds((oldArray) => [...ids, ...default_products_id]);
      setAddOns((oldArray) => [...addons, ...default_products]);
      setAddonsParentCategory((oldArray) => [
        ...addonsParentCategory,
        ...default_products_catId,
      ]);
    }
  }, [defaultProduct, data]);
  useEffect(() => {
    if (
      data === "products" &&
      callingBundles &&
      callingBundles?.length > 0 &&
      defaultRelatedProduct?.length > 0 &&
      products?.length == 0 &&
      rentalProducts?.length == 0
    ) {
      defaultRelatedProduct.forEach((dp) => {
        callingBundles.forEach((cb) => {
          if (dp.category_id == cb.category_id) {
            cb.Subcategory.forEach((element) => {
              if (dp.id == element.id) {
                default_products.push(element);
                default_products_id.push(element.id);
                default_products_catId.push(dp.category_id);
                default_rental_products[dp.category_id] = element.id;
                // default_products_category.push({cb.C}ategory:element.id});
              }
            });
          }
        });
      });
      setAddedRentalProducts(default_rental_products);
      setAddedRentCategory((addedCategory) => [
        ...addedCategory,
        ...default_products_catId,
      ]);
      setRentalProducts((oldArray) => [...oldArray, ...default_products]);
      // setAddedRentalProducts({
      //   ...addedRentalProducts,
      //   [res.Category]: subcat.id,
      // });
      setRental_Ids((oldArray) => [...oldArray, ...default_products_id]);
    }
  }, [defaultRelatedProduct, data]);

  // new

  const [loading, setLoading] = useState(false);
  const [userDetails, setuserDetails] = useState({});
  const [planDetails, setplanDetails] = useState({});

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("addon_ids") !== null) {
      setIds(JSON.parse(localStorage.getItem("addon_ids")));
    }
    if (localStorage.getItem("addons") !== null) {
      setAddOns(JSON.parse(localStorage.getItem("addons")));
    }
    if (localStorage.getItem("related_ids") !== null) {
      setRelated_Ids(JSON.parse(localStorage.getItem("related_ids")));
    }
    if (localStorage.getItem("addonproducts") !== null) {
      setProducts(JSON.parse(localStorage.getItem("addonproducts")));
    }
    if (localStorage.getItem("rental_ids") !== null) {
      setRental_Ids(JSON.parse(localStorage.getItem("rental_ids")));
    }
    if (localStorage.getItem("rentalProducts") !== null) {
      setRentalProducts(JSON.parse(localStorage.getItem("rentalProducts")));
    }
    if (localStorage.getItem("addonsParentCategory") !== null) {
      setAddonsParentCategory(
        JSON.parse(localStorage.getItem("addonsParentCategory"))
      );
    }
  }, []);
  const [routers, setRouters] = useState([]);
  console.log(routers, "routers");

  useEffect(() => {
    var url = APIURL() + `routers-list`;
    var id = JSON.parse(localStorage.getItem("Product")).id;
    const data = { proId: id };
    axiosPost(url, data).then((response) => {
      setRouters(response.data[0].response.data);
    });
  }, []);
  // useEffect(() => {
  //   var price = 0;
  //   var del_price = 0;
  //   if (addons && addons.length > 0) {
  //     addons.forEach((element) => {
  //       price += element.price;
  //     });
  //   }
  //   if (rentalProducts) {
  //     if (rentalProducts.length > 0) {
  //       rentalProducts.forEach((element) => {
  //         price += element.price;
  //       });
  //     }
  //   }
  //   localStorage.setItem("rentalProducts", JSON.stringify(rentalProducts));
  //   localStorage.setItem("rental_ids", JSON.stringify(rental_ids));
  //   localStorage.setItem(
  //     "addedRentalProducts",
  //     JSON.stringify(addedRentalProducts)
  //   );
  //   localStorage.setItem(
  //     "addedRentCategory",
  //     JSON.stringify(addedRentCategory)
  //   );
  //   // setMonthlyTotal(Number(startingmonthlyCost) + Number(price));
  // }, [addons, rentalProducts]);
  const [thumbsSwipers, setThumbsSwipers] = useState({});
  const handleThumbSwiper = (swiper, index) => {
    setThumbsSwipers((prevState) => ({
      ...prevState,
      [index]: swiper,
    }));
  };

  return (
    <section className="container">
      {routers.map(
        (res, routerIndex) =>
          res.Subcategory &&
          res.Subcategory.map((subcat, subcatIndex) => (
            <div key={`${routerIndex}-${subcatIndex}`} className="mb-4">
              <div className="row swiper-bx-sec">
                {subcat.images.length > 0 ? (
                  <div className="col-lg-5">
                    <div className="swiper-container-wrapper umaaswipeerr">
                      <Swiper
                        direction="vertical"
                        onSwiper={(swiper) =>
                          handleThumbSwiper(
                            swiper,
                            `${routerIndex}-${subcatIndex}`
                          )
                        }
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="gallery-thumbs mx-2 ssddd"
                      >
                        {subcat.images.map((image, imageIndex) => (
                          <SwiperSlide key={imageIndex}>
                            <img src={image} alt={`Thumbnail ${imageIndex}`} />
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <Swiper
                        style={{
                          "--swiper-navigation-color": "#000",
                          "--swiper-pagination-color": "#000",
                        }}
                        spaceBetween={10}
                        navigation={true}
                        slidesPerView={1}
                        thumbs={{
                          swiper:
                            thumbsSwipers[`${routerIndex}-${subcatIndex}`],
                        }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="gallery-top"
                      >
                        {subcat.images.map((image, imageIndex) => (
                          <SwiperSlide key={imageIndex}>
                            <Zoom>
                              <img src={image} alt={`Slide ${imageIndex}`} />
                            </Zoom>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-5"></div>
                )}
                <div className="col-lg-4">
                  <div className="swipe-content-bx">
                    <h2>{subcat.name}</h2>
                    <span>Features</span>
                    <div
                      className="qqwwee"
                      dangerouslySetInnerHTML={{ __html: subcat.description }}
                    ></div>
                  </div>
                </div>
                <div className="col-lg-3 align-self-end">
                  <div className="swipe-bx-btn">
                    <div className="price-bx">
                      <h3>£{subcat.price.toFixed(2)}</h3>
                      <p>per month</p>
                    </div>
                    {addons && addons.length > 0 ? (
                      ids.includes(subcat.id) ? (
                        <a
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            setAddonsParentCategory([]);
                            removeAddons_multiple(subcat);
                          }}
                        >
                          Remove
                        </a>
                      ) : addonsParentCategory.includes(res.category_id) ? (
                        <a
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            replaceAddons(subcat, res.category_id);
                          }}
                        >
                          Select this router
                        </a>
                      ) : (
                        <></>
                      )
                    ) : (
                      ""
                    )}
                    {(products && products.length > 0) || rentalProducts ? (
                      related_ids.includes(subcat.id) ||
                      rental_ids.includes(subcat.id) ? (
                        <div>
                          <a
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => removeProduct(subcat, res)}
                          >
                            Selected
                          </a>
                        </div>
                      ) : addedCategory.includes(res.category_id) ||
                        addedRentCategory.includes(res.category_id) ? (
                        <>
                          <a
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => replaceExistingProduct(subcat, res)}
                          >
                            Select this router
                          </a>
                          {/* <a
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                className="btn-add"
                                                                onClick={(e) =>
                                                                  replaceExistingProductDirect(
                                                                    subcat,
                                                                    res
                                                                  )
                                                                }
                                                              >
                                                                Select
                                                              </a> */}
                        </>
                      ) : subcat.service_type == "rental" ? (
                        <div>
                          <a
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => addNewRentalProduct(subcat, res)}
                          >
                            Select this router
                          </a>
                          {/* <a
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                className="btn-add"
                                                                onClick={(e) =>
                                                                  addToExistingProduct(
                                                                    subcat,
                                                                    res
                                                                  )
                                                                }
                                                              >
                                                                {Number(
                                                                  subcat.direct_price
                                                                ) != 0
                                                                  ? "Select"
                                                                  : "Free"}
                                                              </a> */}
                        </div>
                      ) : (
                        <>
                          <a
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => addNewRentalProduct(subcat, res)}
                          >
                            Select this router
                          </a>
                          {/* <a
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              className="btn-add"
                                                              onClick={(e) =>
                                                                addToExistingProduct(
                                                                  subcat,
                                                                  res
                                                                )
                                                              }
                                                            >
                                                              {Number(
                                                                subcat.direct_price
                                                              ) != 0
                                                                ? "Select"
                                                                : "Free"}
                                                            </a> */}
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
      )}
      <div className="row swiper-bx-sec mt-3">
        <div className="col-lg-8">
          <div className="swipe-own-route">
            <h4>Use your own compatible router</h4>
            <p>
              You will need to ensure that your router is compatible with the
              service that you have chosen
              <br />
              You will also need the ability to set up and program your order.
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="swipe-bx-btn">
            {ownRouter == 2 || !ownRouter ? (
              <a style={{ cursor: "pointer" }} onClick={removeAllProducts}>
                Use Your Own Router
              </a>
            ) : (
              <a style={{ cursor: "pointer" }} onClick={() => setOwnRouter(2)}>
                Selected
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Products;
