import React, { useState, useEffect, useCallback } from "react";
import tickets from "../../images/ticket.jpg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../Methods/Save";
import "draft-js/dist/Draft.css";
import { TextField } from "@mui/material";
import Dropzone from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function CreateTicket(props) {
  const { setTab, setMessage, setAlertType, setOpen } = props;
  const [pageDetails, setPageDetails] = useState([]);
  const [priority, setPriority] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [ticketDetails, setTicketDetails] = useState({
    user_id: "",
    service_type: "",
    subject: "",
    description: "",
    status: 1,
    priority: "",
    attachment: "",
    order_no: "",
  });
  useEffect(() => {
    var url = APIURL() + `ticket-priority`;
    axiosGet(url).then((response) => {
      setPriority(response.data[0].response.data);
    });
    url = APIURL() + `ticket-service-type`;
    axiosGet(url).then((response) => {
      setServiceType(response.data[0].response.data);
    });
  }, []);
  const createTicket = () => {
    var user_id = localStorage.getItem("user_id");
    var url = APIURL() + `ticket`;

    const formData = new FormData();
    for (let file of uploadedImages) {
      formData.append("attachment[]", file);
    }
    formData.append("user_id", user_id);
    formData.append("service_type", ticketDetails.service_type);
    formData.append("subject", ticketDetails.subject);
    formData.append("description", ticketDetails.description);
    formData.append("status", ticketDetails.status);
    formData.append("priority", ticketDetails.priority);
    formData.append("order_no", ticketDetails.order_no);

    axios({
      method: "post",
      url: url,
      data: formData,
      headers: {
        Authorization: "Bearer Bearer POPTELECOM@987612",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.data[0].response.status === "success") {
        setMessage("Ticket Created Successfully");
        setAlertType("success");
        setOpen(true);
        setTab("tickets");
      } else {
        setMessage("Technical issue,Please try again");
        setAlertType("error");
        setOpen(true);
        setTab("tickets");
      }
    });
  };
  useEffect(() => {
    setTicketDetails({ ...ticketDetails, attachment: uploadedImages });
  }, [uploadedImages]);
  useEffect(() => {
    var user_id = localStorage.getItem("user_id");
    var url = APIURL() + `order/list`;
    var data = {
      user_id: user_id,
    };
    axiosPost(url, data).then((response) => {
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  const handlechange = (e) => {
    const { name, value } = e.target;
    setTicketDetails({ ...ticketDetails, [name]: value });
  };
  const getImageFromUpload = (data) => {
    return URL.createObjectURL(data);
  };
  const handleImageRemove = (data) => {
    const filteredPeople = uploadedImages.filter(
      (item) => item.name !== data.name
    );
    setUploadedImages(filteredPeople);
  };
  return (
    <>
      <div className="col-lg-9">
        <div className="btn btn-primary" onClick={() => setTab("tickets")}>
          <ArrowBackIcon sx={{ cursor: "pointer", color: "white" }} />
        </div>

        <div className="ticket-box">
          <div className="row flex-box">
            <div className="col-lg-7">
              <div className="ticket-form-sec">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor className="form-label">
                        Order Number
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="order_no"
                        onChange={(e) => handlechange(e)}
                      >
                        <option value="" selected>
                          Order Number
                        </option>
                        {pageDetails && pageDetails.length > 0
                          ? pageDetails.map((pageDetail, index) => (
                              <option key={index} value={pageDetail.order_no}>
                                {pageDetail.order_no}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor className="form-label">
                        Support Type
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="service_type"
                        onChange={(e) => handlechange(e)}
                      >
                        {serviceType && serviceType.length > 0
                          ? serviceType.map((type, index) => (
                              <option key={index} value={type.id}>
                                {type.name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor className="form-label">
                        Priority
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="priority"
                        onChange={(e) => handlechange(e)}
                      >
                        {priority && priority.length > 0
                          ? priority.map((type, index) => (
                              <option key={index} value={type.id} selected>
                                {type.name}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor className="form-label">
                        Subject
                      </label>
                      <input
                        value={ticketDetails.subject}
                        onChange={(e) => handlechange(e)}
                        name="subject"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="App">
                      <p>Description</p>
                      {/*<CKEditor
                        editor={ClassicEditor}
                        data={ticketDetails.description}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setTicketDetails({
                            ...ticketDetails,
                            description: data,
                          });
                        }}
                        onBlur={(event, editor) => {}}
                        onFocus={(event, editor) => {}}
                        onInit={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log("Editor is ready to use!", editor);
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "200px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                      />*/}
                      <textarea
                        value={ticketDetails.description}
                        onChange={(e) => handlechange(e)}
                        name="description"
                        type="text"
                        className="form-control"
                        rows={6}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="tab-pane active" id="home" role="tabpanel">
                      <Dropzone
                        accept="image/*"
                        onDrop={(acceptedFiles) => {
                          acceptedFiles.map((res) => {
                            if (
                              res.type == "image/jpeg" ||
                              res.type == "image/jpg" ||
                              res.type == "image/png"
                            ) {
                              setUploadedImages((oldArray) => [
                                ...oldArray,
                                res,
                              ]);
                            } else {
                            }
                          });
                        }}
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <div
                            {...getRootProps({
                              className: "dropzone",
                            })}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>Drop the files here ...</p>
                            ) : (
                              <p>
                                Drag 'n' drop some files here, or click to
                                select files
                              </p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                      {uploadedImages.length > 0 && (
                        <React.Fragment>
                          <h4 style={{ marginTop: "20px" }}>
                            Uploaded Files :
                          </h4>
                          <div className="row">
                            {uploadedImages.map((res) => (
                              <div className="col-lg-2 col-md-2">
                                <img
                                  style={{
                                    height: "100px",
                                    width: "100%",
                                  }}
                                  src={getImageFromUpload(res)}
                                  alt="img"
                                />
                                <DeleteIcon
                                  onClick={() => handleImageRemove(res)}
                                  style={{
                                    marginTop: "-124%",
                                    marginLeft: "86%",
                                    background: "#fff",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mt-3 text-center">
                      <button
                        onClick={createTicket}
                        type="button"
                        className="btn btn-primary"
                      >
                        Submit Ticket
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="ticket-img-ab">
                <img src={tickets} alt="banner_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateTicket;
