import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import CookieContent from "../Components/CookiePolicy/CookieContent";

function CookiePolicy() {
  return (
    <>
      <Header />
      <div className="body-box-margin">
        <CookieContent />
        <Footer />
      </div>
    </>
  );
}

export default CookiePolicy;
