import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import tyImage from "../../images/thankyou.jpg";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../Methods/Save";
import { useCookies } from "react-cookie";

function Content({ paymentMode }) {
  const [cookies] = useCookies(["dtt_reqid"]);
  const [advId, setAdvId] = useState();
  useEffect(() => {
    try {
      var paymentModeee = paymentMode ? paymentMode : "creditcard";
      var url = APIURL() + "pay-my-bill-success";
      var data = {
        ...JSON.parse(localStorage.getItem("successPaymentDetails")),
        payment_mode: paymentModeee,
      };
      if (localStorage.getItem("paymentDone") == null)
        axiosPost(url, data).then((res) => {
          if (res.data[0].response.status === "success")
            localStorage.setItem("paymentDone", true);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  console.log(cookies["dtt_reqid"], "dttReqIdCookie");
  useEffect(() => {
    const order_id = localStorage.getItem("order_id");
    const product = localStorage.getItem("Product");
    const productId = product && JSON.parse(product).id;
    const dttReqIdCookie = cookies["dtt_reqid"];
    console.log(dttReqIdCookie, "dttReqIdCookie");

    if (order_id && advId && dttReqIdCookie) {
      // const dtTrackerScript = document.createElement("script");
      // dtTrackerScript.src = `https://affsecure.com/p.ashx?a=379&t=${order_id}&f=js&ecsk=${productId}&ecqu=1&ecpr=0&ect=0&r=${dttReqIdCookie}`;
      // document.head.appendChild(dtTrackerScript);
      fireCakePixel(advId, order_id, productId, dttReqIdCookie);
    }
  }, [advId, cookies]);
  useEffect(() => {
    var url = APIURL() + "get-dt-details";
    axiosGet(url).then((response) => {
      setAdvId(response.dt_code);
    });
  }, []);
  const fireCakePixel = (advertiserId, transactionId, productSku, dttReqId) => {
    if (dttReqId) {
      const baseUrl = "https://affsecure.com/p.ashx?";
      const queryParams = new URLSearchParams({
        a: advertiserId,
        t: transactionId,
        ecsk: productSku,
        r: dttReqId,
        ecqu: 1,
        ecpr: 0,
        ect: 0,
        f: "js",
      });

      const pixelUrl = `${baseUrl}${queryParams.toString()}`;

      fetch(pixelUrl)
        .then((response) => response.text())
        .then((data) => {
          console.log("Pixel fired successfully:", data);
        })
        .catch((error) => {
          console.error("Error firing pixel:", error);
        });
    }
  };
 

  return (
    <>
      <div>
        <section className="contact-header">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-xl-8 wow fadeInRightBig mx-auto"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInRightBig",
                }}
              >
                <div className="inner-title-small">
                  <h3 className="p-2"></h3>
                  <h3>Thank you</h3>
                  <div>
                    <p>
                      If you need to get in touch, you can contact our friendly
                      helpful team via phone, email or live chat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-140 payment-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tnk-box">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                      <div className="tnk-box-img">
                        <img src={tyImage} alt />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="tnk-content-box">
                        <div className="tnkyou-content">
                          <h3>Thank You !</h3>
                          <h4>
                            Your Payment is Successfull will get back to you{" "}
                          </h4>
                        </div>
                        <div>
                          <Link to="/" className="pay-btn mb-3">
                            Keep Shopping
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Content;
