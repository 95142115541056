import React, { useEffect, useState } from "react";
import Title from "../Title";
import { Link } from "react-router-dom";
import { axiosGet } from "../../Methods/Save";
import { APIURL } from "../../Methods/Fetch";

function CookieContent() {
  const [pageDetails, setPageDetails] = useState({});

  useEffect(() => {
    var url = APIURL() + "cookie-details-page";
    axiosGet(url).then((response) => {
      setPageDetails(response.data);
    });
  }, []);
  console.log(pageDetails);
  return (
    <div>
      <Title title={pageDetails?.title} />

      <section className="article-header">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-xl-8 wow fadeInRightBig mx-auto"
              data-wow-delay="0.2s"
            >
              <div className="inner-title-small">
                <h3>Cookie policy</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="privacy-box-sec" dangerouslySetInnerHTML={{ __html:pageDetails?.content }}>
        
      </section>
    </div>
  );
}

export default CookieContent;
