import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const CookieHandler = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['dtt_reqid']);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dttReqId = queryParams.get('dtt_reqid');
    console.log(dttReqId);
    if (dttReqId) {
      // Set expiry time for the cookie (e.g., 1 day from now)
      const expires = new Date();
      expires.setDate(expires.getDate() + 1); // 1 day expiry
      setCookie('dtt_reqid', dttReqId, { path: '/', expires }); // Store the value of dtt_reqid in a cookie with expiry
    } else {
      // Optionally, remove the cookie if dtt_reqid is not present
      removeCookie('dtt_reqid', { path: '/' });
    }
  }, [location.search, setCookie, removeCookie]);

  return null;
};

export default CookieHandler;
