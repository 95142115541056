import React, { useState, useEffect, useCallback } from "react";
import tickets from "../../images/ticket.jpg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { APIURL } from "../../Methods/Fetch";
import { axiosGet, axiosPost } from "../../Methods/Save";
import "draft-js/dist/Draft.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function CreateTask(props) {
  const { setTab, setMessage, setAlertType, setOpen } = props;
  const [pageDetails, setPageDetails] = useState([]);
  const [priority, setPriority] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [ticketDetails, setTicketDetails] = useState({
    user_id: "",
    subject: "",
    description: "",
    status: 1,
  });

  useEffect(() => {
    var url = APIURL() + `ticket-priority`;
    axiosGet(url).then((response) => {
      setPriority(response.data[0].response.data);
    });
    url = APIURL() + `ticket-service-type`;
    axiosGet(url).then((response) => {
      setServiceType(response.data[0].response.data);
    });
  }, []);
  const createTicket = () => {
    var user_id = localStorage.getItem("user_id");
    var url = APIURL() + `task`;
    var data = {
      ...ticketDetails,
      user_id: user_id,
    };

    axiosPost(url, data).then((response) => {
      if (response.data[0].response.status === "success") {
        setMessage("Ticket Created Successfully");
        setAlertType("success");
        setOpen(true);
        setTab("tasks");
      } else {
        setMessage("Technical issue,Please try again");
        setAlertType("error");
        setOpen(true);
        setTab("tasks");
      }
    });
  };
  useEffect(() => {
    setTicketDetails({ ...ticketDetails, attachment: uploadedImages });
  }, [uploadedImages]);
  useEffect(() => {
    var user_id = localStorage.getItem("user_id");
    var url = APIURL() + `order/list`;
    var data = {
      user_id: user_id,
    };
    axiosPost(url, data).then((response) => {
      setPageDetails(response.data[0].response.data);
    });
  }, []);
  const handlechange = (e) => {
    const { name, value } = e.target;
    setTicketDetails({ ...ticketDetails, [name]: value });
  };
  const getImageFromUpload = (data) => {
    return URL.createObjectURL(data);
  };
  const handleImageRemove = (data) => {
    const filteredPeople = uploadedImages.filter(
      (item) => item.name !== data.name
    );
    setUploadedImages(filteredPeople);
  };
  return (
    <>
      <div className="col-lg-9">
        <div className="btn btn-primary" onClick={() => setTab("tasks")}>
          <ArrowBackIcon sx={{ cursor: "pointer", color: "white" }} />
        </div>

        <div className="ticket-box">
          <div className="row flex-box">
            <div className="col-lg-7">
              <div className="ticket-form-sec">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label htmlFor className="form-label">
                        Subject
                      </label>
                      <input
                        value={ticketDetails.subject}
                        onChange={(e) => handlechange(e)}
                        name="subject"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>                
                  <div className="col-lg-12">
                    <div className="App">
                      <p>Description</p>
                      <CKEditor
                        editor={ClassicEditor}
                        data={ticketDetails.description}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setTicketDetails({
                            ...ticketDetails,
                            description: data,
                          });
                        }}
                        onBlur={(event, editor) => {
                        }}
                        onFocus={(event, editor) => {
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3 text-center">
                      <button
                        onClick={createTicket}
                        type="button"
                        className="btn btn-primary"
                      >
                        Submit Task
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="ticket-img-ab">
                <img src={tickets} alt="banner_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateTask;
